<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>

      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="直补资金的发放情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-row>
              <el-col>
                <div class="leftTitletStyle">县（市、区）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              合计
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="">
                <div class="leftTitletStyle">计划</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" class="">
                <div class="leftTitletStyle">完成</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">
              一季度
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">计划</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">完成</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">兑现时间（年月）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">
              二季度
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">计划</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">完成</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">兑现时间（年月）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">
              三季度
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">计划</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">完成</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">兑现时间（年月）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view1">
            <div class="firstTitletStyle">
              四季度
            </div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8">
                <div class="leftTitletStyle">计划</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">完成</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">资金（万元）</div>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="24">
                    <div class="leftTitletStyle">兑现时间（年月）</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('totalPlannedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('totalCompletedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q1PlannedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q1CompletedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle"></div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q2PlannedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q2CompletedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q3PlannedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q3CompletedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q4PlannedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('q4CompletedFunds') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 160px;" v-for="(col, index) in listData" :key="index">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>
                    <el-row v-if="type == 'edit'">
                      <div class="leftTitletStyle"></div>
                    </el-row>
                  </el-col>
                  <el-col>
                    <template v-for="key1 in Object.keys(col)">
                      <el-row v-if="key1 == 'countyId'">
                        <div class="contentStyle">
                          <el-cascader  :append-to-body="false"  
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            ref="refDep"
                            size="mini"
                            :options="levalRegion"
                            :props="treeProps"
                            @change="handleChange($event, index)"
                            :show-all-levels="false"
                            placeholder="请选择"
                          >
                          </el-cascader>
                          <div v-else class="box_view">{{ col['county'] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'totalPlannedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'totalCompletedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q1PlannedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q1CompletedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q1CompletionDate'">
                        <div class="contentStyle">
                          <el-date-picker
                            size="mini"
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            type="month"
                            format="yyyy年MM月"
                            placeholder="选择日期"
                          >
                          </el-date-picker>

                          <div v-else class="box_view">
                            {{ col[key1] ? timeFormate(col[key1], 'YYYY年MM月') : '--' }}
                          </div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q2PlannedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q2CompletedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q2CompletionDate'">
                        <div class="contentStyle">
                          <el-date-picker
                            size="mini"
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            type="month"
                            format="yyyy年MM月"
                            placeholder="选择日期"
                          >
                          </el-date-picker>

                          <div v-else class="box_view">
                            {{ col[key1] ? timeFormate(col[key1], 'YYYY年MM月') : '--' }}
                          </div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q3PlannedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q3CompletedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q3CompletionDate'">
                        <div class="contentStyle">
                          <el-date-picker
                            size="mini"
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            type="month"
                            format="yyyy年MM月"
                            placeholder="选择日期"
                          >
                          </el-date-picker>

                          <div v-else class="box_view">
                            {{ col[key1] ? timeFormate(col[key1], 'YYYY年MM月') : '--' }}
                          </div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q4PlannedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q4CompletedFunds'">
                        <div class="contentStyle">
                          <el-input
                            size="mini"
                            oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] ? col[key1] : '--' }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'q4CompletionDate'">
                        <div class="contentStyle">
                          <el-date-picker
                            size="mini"
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            type="month"
                            format="yyyy年MM月"
                            placeholder="选择日期"
                          >
                          </el-date-picker>

                          <div v-else class="box_view">
                            {{ col[key1] ? timeFormate(col[key1], 'YYYY年MM月') : '--' }}
                          </div>
                        </div>
                      </el-row>
                    </template>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

import {
  _DirectFundsAdd,
  _DirectFundsInfo,
  _DirectFundsEdit
} from '@/api/modular/postImmigrationService/policy/subsidyFunds.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      loading: false,
      options: [],
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      levalRegion: []
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 160 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      let sum = 0
      for (let i = 0; i < this.listData.length; i++) {
        let ele = this.listData[i]
        sum += Number(ele[name])
      }
      return sum
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      // let obj = {
      //   year: this.searchObj.year, //年份
      //   countyId: null, //县（市区）
      //   county: '总计', //县（市区）
      //   planningCompilation: 0, //规划编制 规划总数（个）
      //   planningApproval: 0, //规划审批 规划总数（个）
      //   planningCompilationSubtotal: 0, //小计 移民后扶规划编制（个）
      //   countyPlanningCompilation: 0, //以县为单位编制的规划 移民后扶规划编制（个）
      //   engineeringPlanningCompilation: 0, //以工程为单位编制的规划（方案） 移民后扶规划编制（个
      //   planningApprovalSubtotal: 0, //小计 移民后扶规划审批（个）
      //   countyPlanningApproval: 0, //以县为单位编制的规划 移民后扶规划审批（个）
      //   engineeringPlanningApproval: 0, //以工程为单位编制的规划（方案） 移民后扶规划审批（个）
      //   fourteenFivePlanningCompilation: 0, //编制（个） 移民后扶“十四五”规划
      //   fourteenFivePlanningApproval: 0, //审批（个） 移民后扶“十四五”规划
      //   remarks: '', //备注
      //   countyPids: '' //pids
      // }
      // this.listData.unshift(obj)
      // this.getBasicInfo()
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      // let obj = {
      //   year: this.searchObj.year, //年份
      //   countyId: null, //县（市区）
      //   county: '总计', //县（市区）
      //   planningCompilation: 0, //规划编制 规划总数（个）
      //   planningApproval: 0, //规划审批 规划总数（个）
      //   planningCompilationSubtotal: 0, //小计 移民后扶规划编制（个）
      //   countyPlanningCompilation: 0, //以县为单位编制的规划 移民后扶规划编制（个）
      //   engineeringPlanningCompilation: 0, //以工程为单位编制的规划（方案） 移民后扶规划编制（个
      //   planningApprovalSubtotal: 0, //小计 移民后扶规划审批（个）
      //   countyPlanningApproval: 0, //以县为单位编制的规划 移民后扶规划审批（个）
      //   engineeringPlanningApproval: 0, //以工程为单位编制的规划（方案） 移民后扶规划审批（个）
      //   fourteenFivePlanningCompilation: 0, //编制（个） 移民后扶“十四五”规划
      //   fourteenFivePlanningApproval: 0, //审批（个） 移民后扶“十四五”规划
      //   remarks: '', //备注
      //   countyPids: '' //pids
      // }
      // this.listData.unshift(obj)
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
      // this.$nextTick(() => {
      //   this.$refs.table.doLayout()
      // })
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            totalPlannedFunds: null, //计划资金合计
            totalCompletedFunds: null, //完成资金合计
            q1PlannedFunds: null, //一季度计划资金
            q1CompletedFunds: null, //一季度完成资金
            q1CompletionDate: '', //一季度完成兑现时间
            q2PlannedFunds: null, //二季度计划资金
            q2CompletedFunds: null, //二季度完成资金
            q2CompletionDate: '', //二季度完成兑现时间
            q3PlannedFunds: null, //三季度计划资金
            q3CompletedFunds: null, //三季度完成资金
            q3CompletionDate: '', //三季度计划资金

            q4PlannedFunds: null, //四季度计划资金
            q4CompletedFunds: null, //四季度完成资金
            q4CompletionDate: '', //四季度完成兑现时间

            countyPids: '' //pids
          }

          this.listData.splice(this.listData.length, 0, obj)
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }
      _DirectFundsInfo(params).then(res => {
        if (res.code == 200) {
          this.listData = [res.data]
          // let obj = {
          //   year: this.searchObj.year, //年份
          //   countyId: null, //县（市区）
          //   county: '', //县（市区）
          //   totalPlannedFunds: null, //计划资金合计
          //   totalCompletedFunds: null, //完成资金合计
          //   q1PlannedFunds: null, //一季度计划资金
          //   q1CompletedFunds: null, //一季度完成资金
          //   q1CompletionDate: '', //一季度完成兑现时间
          //   q2PlannedFunds: null, //二季度计划资金
          //   q2CompletedFunds: null, //二季度完成资金
          //   q2CompletionDate: '', //二季度完成兑现时间
          //   q3PlannedFunds: null, //三季度计划资金
          //   q3CompletedFunds: null, //三季度完成资金
          //   q3CompletionDate: '', //三季度计划资金

          //   q4PlannedFunds: null, //四季度计划资金
          //   q4CompletedFunds: null, //四季度完成资金
          //   q4CompletionDate: '', //四季度完成兑现时间

          //   countyPids: '' //pids
          // }
          // this.listData.unshift(obj)
          console.log(this.listData, '========获取详情==========')
        }
      })
    },

    submitForm() {
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          planningCompilation: ele.planningCompilation,
          planningApproval: ele.planningApproval,
          planningCompilationSubtotal: ele.planningCompilationSubtotal,
          engineeringPlanningCompilation: ele.engineeringPlanningCompilation,
          planningApprovalSubtotal: ele.planningApprovalSubtotal,
          countyPlanningApproval: ele.countyPlanningApproval,
          engineeringPlanningApproval: ele.engineeringPlanningApproval,
          fourteenFivePlanningCompilation: ele.fourteenFivePlanningCompilation,
          fourteenFivePlanningApproval: ele.fourteenFivePlanningApproval
        }
      })

      console.log(listData, '===submitForm===')
      let flag = listData.every(ele => ele.countyId)
      // return
      switch (this.type) {
        case 'add':
          if (flag) {
            _DirectFundsAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
        case 'edit':
          if (flag) {
            _DirectFundsEdit(listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
